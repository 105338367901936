$(document).ready ->
  STEP_US_CITIZEN = {
    step: 'us-citizen',
    progress: '0',
    forward: () ->
      $('#value-' + this.step).text $('#loanapplication-us_citizen option:selected').text()
      if loanApp.requiresEmail
        $('#value-' + this.step + '-email').text $('#loanapplication-email').val()
      if loanApp.requiresLoanSemester
        $('#value-' + this.step + '-loan_semester').text $('#loanapplication-loan_semester option:selected').text()
      usCitizenVal = $('#loanapplication-us_citizen').val();
      if usCitizenVal == '1'
        if loanApp.requiresLoanSemester
          if $('#loanapplication-loan_semester').val() < 1
            $('#loan-app-form').yiiActiveForm 'validateAttribute', 'loanapplication-us_citizen';
            $('#loan-app-form').yiiActiveForm 'validateAttribute', 'loanapplication-loan_semester';
            return true
        loanApp.nextStep = STEP_SCHOOL_INFO
        loanApp.transitionStep true
      else if usCitizenVal == '0'
        if loanApp.requiresLoanSemester
          if $('#loanapplication-loan_semester').val() < 1
            $('#loan-app-form').yiiActiveForm 'validateAttribute', 'loanapplication-us_citizen';
            $('#loan-app-form').yiiActiveForm 'validateAttribute', 'loanapplication-loan_semester';
            return true
        loanApp.nextStep = STEP_US_COSIGNER
        loanApp.transitionStep true
      else
        $('#loan-app-form').yiiActiveForm 'validateAttribute', 'loanapplication-us_citizen';
        if loanApp.requiresEmail
          $('#loan-app-form').yiiActiveForm 'validateAttribute', 'loanapplication-email';
        if loanApp.requiresLoanSemester
          $('#loan-app-form').yiiActiveForm 'validateAttribute', 'loanapplication-loan_semester';
    backward: () ->
      loanApp.nextStep = STEP_US_CITIZEN
      loanApp.slideUpCrumbs ['us-citizen', 'us-cosigner', 'degree-sought', 'grad-date']
      loanApp.transitionStep false
    beforeTransition: () ->
      $('#loanapplication-us_citizen').prop 'selectedIndex', 0
      return true
  }

  STEP_US_COSIGNER = {
    step: 'us-cosigner',
    progress: '25',
    forward: () ->
      $('#value-' + this.step).text $('#loanapplication-with_cosigner option:selected').text()
      usCosignerVal = $('#loanapplication-with_cosigner').val();
      if usCosignerVal == '1'
        loanApp.nextStep = STEP_SCHOOL_INFO
        loanApp.transitionStep true
      else if usCosignerVal == '0'
        loanApp.nextStep = STEP_DEGREE_SOUGHT
        loanApp.transitionStep true
      else
        $('#loan-app-form').yiiActiveForm 'validateAttribute', 'loanapplication-with_cosigner';
    backward: () ->
      loanApp.nextStep = STEP_US_COSIGNER
      loanApp.slideUpCrumbs ['us-cosigner', 'degree-sought', 'grad-date']
      loanApp.transitionStep false
    beforeTransition: () ->
      $('#loanapplication-with_cosigner').prop 'selectedIndex', 0
      return true
  }

  STEP_DEGREE_SOUGHT = {
    step: 'degree-sought',
    progress: '50',
    forward: () ->
      $('#value-' + this.step).text $('#loanapplication-education_level option:selected').text()
      degreeSoughtVal = $('#loanapplication-education_level').val();
      if degreeSoughtVal == '2' #bachelors
        loanApp.nextStep = STEP_GRAD_DATE
        loanApp.transitionStep true
      else if degreeSoughtVal == '3' #masters
        loanApp.nextStep = STEP_SCHOOL_INFO
        loanApp.transitionStep true
      else
        $('#loan-app-form').yiiActiveForm 'validateAttribute', 'loanapplication-education_level';
    backward: () ->
      loanApp.nextStep = STEP_DEGREE_SOUGHT
      loanApp.slideUpCrumbs ['degree-sought', 'grad-date']
      loanApp.transitionStep false
    beforeTransition: () ->
      $('#loanapplication-education_level').prop 'selectedIndex', 0
      return true
  }

  STEP_GRAD_DATE = {
    step: 'grad-date',
    progress: '75',
    forward: () ->
      gradDateVal = $('#loanapplication-graduation_date').val()
      regEx = /^\d{4}-\d{2}-\d{2}$/g
      validDate = regEx.test gradDateVal
      if gradDateVal != '' and validDate == true
        $('#value-' + this.step).text gradDateVal
        loanApp.nextStep = STEP_SCHOOL_INFO
        loanApp.transitionStep true
      else
        $('#loan-app-form').yiiActiveForm 'validateAttribute', 'loanapplication-graduation_date';
    backward: () ->
      loanApp.nextStep = STEP_GRAD_DATE
      loanApp.slideUpCrumbs ['grad-date']
      loanApp.transitionStep false
    beforeTransition: () ->
      $('#loanapplication-graduation_date').val ''
      return true
  }

  STEP_SCHOOL_INFO = {
    step: 'school-info',
    progress: '100',
    forward: () ->
      if $('#loanapplication-school_country').is 'select'
        if $('#loanapplication-school_country').val() < 1
          $('#loan-app-form').yiiActiveForm 'validateAttribute', 'loanapplication-school_country';
          return false;
      if $('#loanapplication-school_state').is 'select'
        if $('#loanapplication-school_state').val() < 1
          $('#loan-app-form').yiiActiveForm 'validateAttribute', 'loanapplication-school_state';
          return false;
      if $('#loanapplication-school_id').is 'select'
        if $('#loanapplication-school_id').val() < 1
          $('#loan-app-form').yiiActiveForm 'validateAttribute', 'loanapplication-school_id';
          return false;
      $('#loan-app-form').submit()
    backward: () ->
      #Since there is no step after this one, we can't go backwards to it.
    beforeTransition: () ->
      if $('#loanapplication-school_id').is 'input'
        #If this field is an input then we can assume its hidden and filled out, so we should go ahead and submit.
        $('#loan-app-form').submit()
        return false;
      else if $('#loanapplication-school_country').is 'select'
        loanApp.addLoader()
        $('#loanapplication-school_country').html ''
        $.ajax
          url: loanApp.countriesActionUrl
          type: 'GET'
          dataType: 'json',
          data: $('#loan-app-form').serialize()
          success: (data) ->
            $('#loanapplication-school_country').html(data)
          complete: () ->
            loanApp.removeLoader()
          error: (e) ->
            console.log e

      return true
  }

  class LoanApp
    constructor: ->
      @nextStep = ''
      @currentStep = STEP_US_CITIZEN
      @finalStep = STEP_SCHOOL_INFO.step

      #The widget sets these globally, but to be safe we should fallback to the most likely path for each url we use.
      if typeof window.schoolsAction is 'undefined'
        window.schoolsAction = '/apply/schools/'
      @schoolsActionUrl = window.schoolsAction

      if typeof window.regionsAction is 'undefined'
        window.regionsAction = '/apply/regions/'
      @regionsActionUrl = window.regionsAction

      if typeof window.countriesAction is 'undefined'
        window.countriesAction = '/apply/countries/'
      @countriesActionUrl = window.countriesAction

      if typeof window.appRequiresEmail is 'undefined'
        window.appRequiresEmail = false;
      @requiresEmail = window.appRequiresEmail

      if typeof window.appRequiresLoanSemester is 'undefined'
        window.appRequiresLoanSemester = false
      @requiresLoanSemester = window.appRequiresLoanSemester


    transitionStep: (isForward) ->
      #Any functionality custom to the step we are about to move to that should happen before we process the transition
      shouldContinue = @nextStep.beforeTransition()
      if shouldContinue
        if @nextStep.step == @finalStep
          $('#loan-app-next-btn').text 'Find My Loan'
        else
          $('#loan-app-next-btn').text 'Next'
        if isForward
          $('#loan-crumb-' + @currentStep.step).slideDown()
        $('#step-' + @currentStep.step).hide()
        $('#step-' + @nextStep.step).show()
        $('.progress-bar').attr 'style', 'width:' + @nextStep.progress + '%;'
        #Reset Steps
        @currentStep = @nextStep
        @nextStep = ''

    slideUpCrumbs: (steps) ->
      for i in [0...steps.length]
        $('#loan-crumb-' + steps[i]).slideUp()

    addLoader: () ->
      $('#loan-step-container').prepend '<i id="loan-loader-icon" class="fa fa-circle-o-notch fa-spin fa-4x fa-fw" style="position:absolute;top: 50%;left: 50%;margin-left: -35px;"></i>'
      $('#loan-step-container').css 'opacity', '0.4'

    removeLoader: () ->
      $('#loan-step-container #loan-loader-icon').remove()
      $('#loan-step-container').css 'opacity', '1.0'

    $('.datepicker').attr 'autocomplete', 'off'

    clearSchool: () ->
      schoolIsSelect = $('#loanapplication-school_id').is 'select'
      stateIsSelect = $('#loanapplication-school_state').is 'select'
      if schoolIsSelect and stateIsSelect
        $('#loanapplication-school_id').attr 'disabled', true
        $('#loanapplication-school_id').html('')
      else if schoolIsSelect
        $('#loanapplication-school_id').prop 'selectedIndex', 0

    clearState: () ->
      if $('#loanapplication-school_state').is 'select'
        $('.field-loanapplication-school_state').hide()
        $('#loanapplication-school_state').html('')

    run: () ->
      _this = @
      $('#loan-app-next-btn').on 'click', ->
        _this.currentStep.forward()

      #Backwards Progress Event Handlers
      $('#us-citizen-edit').on 'click', ->
        _this.clearSchool()
        _this.clearState()
        STEP_US_CITIZEN.backward()
      $('#us-cosigner-edit').on 'click', ->
        _this.clearSchool()
        _this.clearState()
        STEP_US_COSIGNER.backward()
      $('#degree-sought-edit').on 'click', ->
        _this.clearSchool()
        _this.clearState()
        STEP_DEGREE_SOUGHT.backward()
      $('#grad-date-edit').on 'click', ->
        _this.clearSchool()
        _this.clearState()
        STEP_GRAD_DATE.backward()

      if $('#loanapplication-school_country').is 'select'
        $('#loanapplication-school_country').on 'change', ->
          _this.addLoader()
          _this.clearSchool()
          _this.clearState()
          countryId = $(this).val()
          if countryId != ''
            $.ajax
              url: _this.regionsActionUrl
              type: 'GET'
              dataType: 'json',
              data: {countryId: countryId}
              success: (data) ->
                $('#loanapplication-school_state').html(data)
                if countryId == '254'
                  $('.field-loanapplication-school_state').show()
                #Since state data is changing we should manually trigger the change event
                $('#loanapplication-school_state').trigger 'change'
              complete: () ->
                _this.removeLoader()
              error: (e) ->
                console.log e
          else
            _this.removeLoader()

      if $('#loanapplication-school_state').is 'select'
        $('#loanapplication-school_state').on 'change', ->
          _this.addLoader()
          _this.clearSchool()
          if $(this).val() != ''
            $.ajax
              url: _this.schoolsActionUrl
              type: 'GET'
              dataType: 'json',
              data: $('#loan-app-form').serialize()
              success: (data) ->
                $('#loanapplication-school_id').html(data)
                $('#loanapplication-school_id').attr 'disabled', false
              complete: () ->
                _this.removeLoader()
              error: (e) ->
                console.log e
          else
            _this.removeLoader()

  loanApp = new LoanApp()
  loanApp.run()
